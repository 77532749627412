const PREFIX_URL = '/research/security-behaviour-database/';
// process.env.NODE_ENV === 'production'
//   ? '/research/security-behaviour-database/'
//   : '/';
const BEHAVIOURS_URL = `${PREFIX_URL}behaviours/`;
const RISKS_URL = `${PREFIX_URL}risks/`;

module.exports = {
  PREFIX_URL,
  BEHAVIOURS_URL,
  RISKS_URL
};

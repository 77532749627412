import breakpoints from '../utils/breakpoints';
import styled from '@emotion/styled';

export default styled.div({
  padding: '40px 56px',
  minHeight: '90vh',
  [breakpoints.md]: {
    padding: '32px'
  },
  [breakpoints.sm]: {
    padding: '24px 0'
  }
});

import React from 'react';
import styled from '@emotion/styled';
import { size } from 'polished';
import { MenuItem } from './menu';
import generatedBehavioursForRisks from '../../generate/files/generatedBehavioursForRisks';
import generatedRisksForBehaviours from '../../generate/files/generatedRisksForBehaviours';
import indexDocNavItems from '../../generate/files/indexDocNavItems';

const AppIcon = styled.div({
  ...size('100%'),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 8,
  overflow: 'hidden',
  img: {
    height: 93,
    maxWidth: 'inherit',
    width: 93,
    margin: 0,
    objectFit: 'cover'
  }
});

const MenuWrapper = styled.div({
  display: 'grid',
  gridTemplateColumns: `repeat(auto-fill, minmax(320px, 1fr))`,
  gridGap: 24,
  paddingTop: 8,
  marginBottom: 20,
  paddingBottom: 30,
  borderBottom: '1px solid #C2F1EA'
});

const NavItemDescription = styled.p({
  margin: 0,
  fontSize: '16px !important',
  lineHeight: '20px !important',
  color: '#959DAA !important'
});

const StyledLink = styled.a({
  color: 'inherit',
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'underline'
  }
});

const getNavItems = (type, titlePage) => {
  switch (type) {
    case 'behaviour':
      return generatedRisksForBehaviours[titlePage];
    case 'risk':
      return generatedBehavioursForRisks[titlePage];
    case 'index':
      return indexDocNavItems;
    default:
      return indexDocNavItems;
  }
};

const formatDescription = (text, limit = 115) => {
  if (text.length > limit) {
    let trimmedString = text.substring(0, limit);
    trimmedString = trimmedString.substring(0, trimmedString.lastIndexOf(' '));
    return `${trimmedString} ...`;
  }
  return text;
};

export default function DocsetMenu({ titlePage, type }) {
  const navItems = getNavItems(type, titlePage);
  return (
    <MenuWrapper>
      {navItems.map((navItem, index) => (
        <MenuItem
          key={navItem.url}
          icon={
            <AppIcon>
              <img src={navItem.imageSrc} alt={navItem.title} />
            </AppIcon>
          }
          title={<StyledLink href={navItem.url}>{navItem.title}</StyledLink>}
        >
          <NavItemDescription>
            {formatDescription(navItem.description, 115)}
          </NavItemDescription>
        </MenuItem>
      ))}
    </MenuWrapper>
  );
}

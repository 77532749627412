import React from 'react';
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';

const CSVDownloadForm = () => {
  const { loaded } = useHubspotForm({
    portalId: '4820846',
    formId: '89b7378b-9687-4295-b2db-178c7f5a7b71',
    target: '#my-hubspot-form'
  });

  return <div id="my-hubspot-form">{loaded ? '' : null}</div>;
};

export default CSVDownloadForm;
import Vimeo from '@u-wave/react-vimeo';
import DocsetMenu from "../../../../source/components/docset-menu";
import LearnMoreButton from "../../../../source/components/LearnMoreButton";
import { PREFIX_URL } from "../../../../constants";
import * as React from 'react';
export default {
  Vimeo,
  DocsetMenu,
  LearnMoreButton,
  PREFIX_URL,
  React
};
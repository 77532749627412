import React from 'react';
import styled from '@emotion/styled';

const StyledLink = styled.a({
  display: 'inline',
  margin: 0,
  fontFamily: 'inherit',
  fontSize: '18px',
  color: '#21837f'
});

export default function Link({ textLink, link, target }) {
  return (
    <StyledLink href={link} target={target ? target : '_self'}>
      {textLink}
    </StyledLink>
  );
};

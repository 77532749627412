import 'gatsby-theme-apollo-docs/src/prism.less';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import {
  Layout,
  MenuButton,
  Sidebar,
  SidebarNav,
  Logo,
  breakpoints,
  useResponsiveSidebar
} from 'gatsby-theme-apollo-core';
import Search from './search';
import { Header } from '../../gatsby-theme-apollo-core/components/header';

export const NavItemsContext = '';
const Main = styled.main({
  flexGrow: 1,
  outline: 'none',
  WebkitOverflowScrolling: 'touch'
});

const FlexWrapper = styled.div({
  display: 'flex',
  minHeight: '100vh',
  maxWidth: 1600,
  padding: '0 24px',
  margin: '0 auto'
});

const LogoContainer = styled.div({
  width: 50,
  overflow: 'hidden',
  height: '100%'
});

const MobileNav = styled.div({
  display: 'none',
  [breakpoints.md]: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10
  }
});

const Footer = styled.footer({
  '@media (min-width: 851px)': {
    paddingLeft: '56px',
    margin: '60px 0 10px'
  },

  a: {
    padding: '0 6px',
    color: '#069991'
  }
});

export default function PageLayout(props) {
  const sidebarRef = useRef(null);
  const data = useStaticQuery(
    graphql`
      {
        site {
          siteMetadata {
            title
            siteName
          }
        }
      }
    `
  );

  const { openSidebar, closeSidebar, sidebarOpen } = useResponsiveSidebar();

  function handleWrapperClick(event) {
    if (sidebarOpen && !sidebarRef.current.contains(event.target)) {
      closeSidebar();
    }
  }

  const { pathname } = props.location;

  const {
    siteName
    // title,
  } = data.site.siteMetadata;
  const currentYear = new Date().getFullYear();
  const { sidebarContents } = props.pageContext;

  const { logoLink, algoliaApiKey, algoliaIndexName } = props.pluginOptions;

  const allBehaviours = {
    title: 'Behaviours Menu',
    pages: [1, 2, 3, 4].map((el, idx) => ({
      title: `Tier ${idx + 1}`,
      path: `/tiers/#tier-${idx + 1}`,
      sidebarTitle: '',
      description: ''
    }))
  };

  if (
    sidebarContents &&
    !sidebarContents.some(el => el.title === 'Behaviours Menu')
  ) {
    sidebarContents.push(allBehaviours);
  }

  return (
    <Layout>
      <FlexWrapper onClick={handleWrapperClick}>
        <Sidebar
          responsive
          className="sidebar"
          open={sidebarOpen}
          ref={sidebarRef}
          title={siteName}
          logoLink={logoLink}
        >
          {sidebarContents && (
            <SidebarNav
              contents={sidebarContents}
              pathname={pathname}
              onLinkClick={sidebarOpen ? closeSidebar : null}
            />
          )}
        </Sidebar>
        {/* we give the component a key so it resets the scroll when the pathname changes */}
        <Main key={props.location.pathname} tabIndex={0}>
          <MobileNav>
            <Header>
              <MenuButton onClick={openSidebar} />
              <LogoContainer>
                <Logo />
              </LogoContainer>
            </Header>
          </MobileNav>
          {algoliaApiKey && algoliaIndexName && (
            <Header>
              <Search
                siteName={siteName}
                apiKey={algoliaApiKey}
                indexName={algoliaIndexName}
              />
            </Header>
          )}
          {props.children}
          <Footer>
            SebDB is brought to you by
            <a
              href="https://cybsafe.com"
              title="CybSafe"
              rel="noreferrer"
              target="_blank"
            >
              CybSafe
            </a>
            | © {currentYear} CybSafe Ltd
          </Footer>
        </Main>
      </FlexWrapper>
    </Layout>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  pluginOptions: PropTypes.object.isRequired
};

import React from 'react';
import styled from '@emotion/styled';

export const FurtherReading = styled.div({
  a: {
    color: '#5A6270',
    fontSize: 14,
    display: 'block'
  }
});

const TierShieldContainer = styled.div({
  img: {
    margin: 0,
    width: 55
  }
});

const TierImages = [
  'https://cybsafe-resources.s3-eu-west-1.amazonaws.com/cover+images/tier-1-icon.png',
  'https://cybsafe-resources.s3-eu-west-1.amazonaws.com/cover+images/tier-2-icon.png',
  'https://cybsafe-resources.s3-eu-west-1.amazonaws.com/cover+images/tier-3-icon.png',
  'https://cybsafe-resources.s3-eu-west-1.amazonaws.com/cover+images/tier-4-icon.png'
];

export default function TierShield({ tier }) {
  return (
    <TierShieldContainer>
      <img src={TierImages[tier]} alt={`Tier ${tier}`} />
    </TierShieldContainer>
  );
}

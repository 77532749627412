import React from 'react';
import styled from '@emotion/styled';
import { size } from 'polished';
import { colors } from '@apollo/space-kit/colors';

const MenuItemWrapper = styled.div({
  display: 'flex'
});

const IconWrapper = styled.div({
  ...size(93),
  flexShrink: 0,
  marginRight: 20
});

const TextWrapper = styled.div({
  color: colors.text1
});

const NavItemTitle = styled.h4(`
margin: 0;
padding: 0;
font-size: 20px;
line-height: 27px;
color: #5A6270;
text-transform: lowercase;

&:first-letter {
  text-transform: uppercase;
}
`);

export function MenuItem({ icon, title, children, ...props }) {
  return (
    <MenuItemWrapper {...props}>
      <IconWrapper>{icon}</IconWrapper>
      <TextWrapper>
        <NavItemTitle>{title}</NavItemTitle>
        {children}
      </TextWrapper>
    </MenuItemWrapper>
  );
}

import React from 'react';

export default function Logo() {
  return (
    <img
      src="https://cybsafe-resources.s3-eu-west-1.amazonaws.com/cover+images/+CYBSAFE-SebDB+logo+high+res-200417+MI.svg"
      alt="Security Behaviour Database"
    />
  );
}

import breakpoints from 'gatsby-theme-apollo-core/src/utils/breakpoints';
import styled from '@emotion/styled';
import { colors } from 'gatsby-theme-apollo-core/src/utils/colors';

export const headerHeight = 64;
export const Header = styled.header({
  display: 'flex',
  alignItems: 'center',
  flexShrink: 0,
  height: headerHeight,
  padding: '0',
  background: '#fff',
  color: colors.white,
  position: 'sticky',
  top: 0,
  zIndex: 1
});

export const DesktopHeader = styled(Header)({
  [breakpoints.md]: {
    display: 'none'
  }
});

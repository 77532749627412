import React from 'react';
import styled from '@emotion/styled';

const LearnMoreButton = styled.a({
  background: '#069991',
  border: 0,
  borderRadius: 5,
  padding: '12px 45px',
  color: '#fff',
  display: 'inline-block',
  textDecoration: 'none',
  fontSize: 18,
  lineHeight: '23px',
  margin: '15px 0 30px'
});

export default function LearnMore({ textLink, link, target }) {
  return (
    <LearnMoreButton href={link} target={target ? target : '_self'}>
      {textLink}
    </LearnMoreButton>
  );
}

import React, { Fragment } from 'react';
import styled from '@emotion/styled';
import { size } from 'polished';
import { colors } from '@apollo/space-kit/colors';
import generatedBehavioursForRisks from '../../generate/files/generatedBehavioursForRisks';
import allRisks from '../../generate/files/indexDocNavItems';
const MenuItemWrapper = styled.div({
  display: 'flex',
  marginTop: 20
});

const IconWrapper = styled.div({
  ...size(50),
  flexShrink: 0,
  marginRight: 30
});

const TextWrapper = styled.div({
  color: colors.text1
});

const NavItemTitle = styled.h3({
  margin: 0,
  padding: 0,
  fontSize: 20,
  lineHeight: '27px',
  color: '#5A6270',
  fontWeight: 'bold',
  fontFamily: 'IBM Plex Sans'
});
const AppIcon = styled.div({
  ...size('100%'),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 8,
  overflow: 'hidden',
  img: {
    height: 50,
    maxWidth: 'inherit',
    width: 50,
    margin: 0
  }
});

const NavItemDescription = styled.p({
  margin: 0,
  fontSize: '16px !important',
  lineHeight: '20px !important',
  color: '#959DAA !important'
});

const StyledLink = styled.a({
  color: 'inherit',
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'underline'
  }
});

const BehavioursContainer = styled.ul({
  marginTop: 10,
  marginLeft: 100,
  li: {
    listStyle: 'none',
    a: {
      fontSize: 14,
      fontFamily: 'IBM Plex Mono',
      color: '#5A6270',
      ':hover': {
        color: '#5A6270'
      }
    }
  }
});

const AllBehaviours = props => {
  return allRisks.map(navItem => (
    <Fragment key={navItem.url}>
      <MenuItemWrapper>
        <IconWrapper>
          <AppIcon>
            <img src={navItem.imageSrc} alt={navItem.title} />
          </AppIcon>
        </IconWrapper>
        <TextWrapper>
          <NavItemTitle>
            <StyledLink
              id={navItem.alias}
              href={navItem.url}
              data-link={navItem.alias}
              title={navItem.title}
            >
              {navItem.title}
            </StyledLink>
          </NavItemTitle>
          <NavItemDescription>{navItem.description}</NavItemDescription>
        </TextWrapper>
      </MenuItemWrapper>
      <BehavioursContainer>
        {generatedBehavioursForRisks[navItem.alias].map(behaviour => {
          return (
            <li key={behaviour.url + Math.random() * 100}>
              <a href={behaviour.url}>{behaviour.title}</a>
            </li>
          );
        })}
      </BehavioursContainer>
    </Fragment>
  ));
};

export default AllBehaviours;

import React from 'react';
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';

const CommunityForm = () => {
  const { loaded } = useHubspotForm({
    portalId: '4820846',
    formId: 'a9562a71-01fa-4734-8ed3-b8c0c3f8622d',
    target: '#my-hubspot-form'
  });

  return <div id="my-hubspot-form">{loaded ? '' : null}</div>;
};

export default CommunityForm;
